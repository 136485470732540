/* @import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@100;200;300;400;500;600;700&family=Rubik:wght@300;400;500;600;700;800;900&family=Lobster&display=swap"); */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --main-color: #6e3cbc;
  --button-color: #7267cb;
  --text-color: #98bae7;
  --paragraph-text: rgb(85, 84, 84);
  --button-text-color: #f1f1f1;
}
html {
  scroll-behavior: smooth;
}
section {
  padding: 0 100px;
  /* min-height: 100vh; */
}
::selection {
  background-color: var(--main-color);
  color: var(--button-text-color);
}
h1,
h2,
h3,
h4,
h5 {
  font-family: "Gambarino", serif;
  color: rgb(44, 44, 44);
}
p,
button,
a {
  font-family: "Plus Jakarta Sans", sans-serif;
  color: rgb(116, 116, 116);
}
/* button {
  border: none;
  background: linear-gradient(to right, var(--main-color), var(--text-color));
  border-radius: 5px;
  min-width: 100px;
  min-height: 25px;
  padding: 10px 20px;
  color: var(--button-text-color);
  cursor: pointer;
  letter-spacing: 1px;
  font-size: 14.5px;
  font-weight: 600;
  box-shadow: 3px 3px 20px 5px #e2efff81;
} */
a {
  text-decoration: none;
  cursor: pointer;
  color: var(--paragraph-text);
}
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo {
  /* font-family: "Lobster", cursive; */
  margin-left: 6rem;
}
.footer__logo {
  margin-left: 0;
}
.divider {
  width: 100px;
  height: 3px;
  margin: 0 auto;
  border-radius: 3px;
  margin-bottom: 70px;
  background: linear-gradient(to right, var(--main-color), var(--text-color));
}
.footer {
  min-height: 20vh;
}
.portfolio__head__text {
  font-size: 30px;
  padding-top: 50px;
  text-align: center;
}
.row {
  display: flex;
}
.column {
  padding: 20px 30px;
}
.row a {
  width: 33.33%;
  padding: 20px 40px 40px 0;
}
.row a .far,
.row a .fas {
  font-size: 35px;
  color: var(--button-color);
}
.row a h2 {
  font-weight: 500;
  font-size: 20px;
}
.row a:hover {
  transition: 0.8s ease;
  box-shadow: 3px 3px 20px 5px #e2efff81;
}

@media (max-width: 400px) {
  .NavBar {
    right: 1rem;
  }
}
