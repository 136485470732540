.contact a{
    margin-right: 30px;
}
.contact{
    position: relative;
    background: #d2b7fd33;
    padding: 60px 100px;
    min-height: 25vh;
}
.contact h1{
    font-size: 35px;
}
.contact p{
    font-size: 17px;
}