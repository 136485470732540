.img-container {
  background-image: url("../images/chisom-about.jpg");
  background-size: cover;
  background-position: center;
  width: 400px;
  height: 400px;
  border-radius: 28% 68% 65% 25% / 25% 25% 65% 65%;
  animation: border-radius 5s linear infinite;
}

@keyframes border-radius {
  0% {
    border-radius: 28% 68% 65% 25% / 25% 25% 65% 65%;
  }

  25% {
    border-radius: 53% 37% 70% 20% / 73% 40% 50% 20%;
  }

  50% {
    border-radius: 45% 45% 30% 64% / 50% 25% 70% 40%;
  }

  75% {
    border-radius: 30% 65% 55% 40% / 60% 65% 30% 35%;
  }
}
.hero {
  position: relative;
  display: flex;
  margin-top: 60px;
  /* height: 60vh; */
  border: 2px solid red;
}
.hero_text {
  width: 60%;
}
.intro {
  font-size: 50px;
}
.btn {
  margin-left: 20px;
}
.socials {
  margin-left: 10px;
}
h4 {
  font-size: 20px;
}

@media (max-width: 770px) {
  .hero_text {
    width: 100%;
  }
}
