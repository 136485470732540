.portfolio h1{
    margin: 0 0 20px 0;
}
.portfolio p{
    margin: auto;
    text-align: center;
    width: 500px;
    margin-bottom: 20px;
}
.row a{
    width: 25%;
}
.column{
    padding: 20px 0 0 30px;
}
.row a h2{
    font-weight: 500;
    font-size: 17px;
}
.portfolio{
    margin-bottom: 50px;
}

@media (max-width:600px) {
    .portfolio p{
        width: auto;
        margin-bottom: 20px;
    }
}
@media (max-width:460px) {
    .contact{
        padding: 20px;
    }
    .column{
        padding: 20px 0px;
    }
    .row a{
        width: 100%;
        padding: 20px 20px 20px 0;
    }
}