.about {
  position: relative;
  background: #d2b7fd33;
  padding-bottom: 40px;
}
.head__text {
  text-align: center;
  font-size: 25px;
  padding-top: 50px;
}

.about__content {
  display: flex;
}
.about__img img {
  width: 50%;
  box-shadow: 3px 3px 20px 5px #0000001c;
}
.about__me {
  width: 45%;
  position: absolute;
  right: 100px;
  text-align: justify;
}
