.header{
    padding: 0 100px;
    position: relative;
    text-align: center;
}
.author{
    position: absolute;
    width: 50%;
    background-color: rgba(240, 255, 255, 0.253);
    padding: 30px;
    box-shadow: 1px 10px 20px rgba(148, 148, 148, 0.39);
    text-align: center;
    top: 0%;
    left: 20%;
    text-align: center;
    right: 20%;
    height: auto;
    bottom: 0%; 
    margin: 50px auto;
}
.author h1{
    text-transform: capitalize;
    font-size: xx-large;
    text-align: center;
}
.author p{
    color: rgb(41, 41, 41);
    font-weight: 600;
    font-size: 21px;
    text-transform: capitalize;
}
.body{
    padding: 50px 100px;
}
.main__image{
    height: 100%;
    width: 100%;
}

@media(max-width: 850px){
    .header{
        padding: 0 50px;
    }
    .body{
        padding: 50px;
    }
}
@media(max-width: 500px){
    .header{
        padding: 0;
    }
    .body{
        padding: 20px;
    }
    
.author{
    width: 80%;
    padding: 20px;
    top: 0%;
    left: 5%;
    right: 5%;
    bottom: 0%; 
    margin: 20px auto;
}
}
