@media (max-width: 760px) {
  .logo {
    margin-left: 2rem;
  }
}
@media (max-width: 500px) {
  .logo {
    margin-left: 1rem;
  }
  .NavBar {
    margin-right: -20px;
  }
}
@media (max-width: 1000px) {
  .about {
    height: 160vh;
  }
  .head__text {
    padding-top: 40px;
  }
  .about__img img {
    width: 50%;
  }
}
@media (max-width: 940px) {
  section {
    padding: 0 50px;
  }
  .hero__img {
    right: 50px;
  }
}

@media (max-width: 860px) {
  .row {
    display: block;
  }
  .about {
    height: 180vh;
  }
  .skills {
    display: block;
    height: 160vh;
  }
  .skill__head__text {
    width: 100%;
    padding: 40px 0;
  }
  .skill__head__text h1 {
    font-size: 45px;
  }
  .skill {
    width: 90%;
    right: auto;
  }
  .skill .skill__header {
    padding: 0px 0 0 0;
  }
}
@media (max-width: 770px) {
  .hero {
    display: block;
    height: 120vh;
  }
  .about__me h2 {
    text-align: left;
  }
  /* .hero__text{
      width: 100%;
  } */
  .hero__img {
    display: none;
  }
  .about__content {
    display: block;
  }
  /* .about__me {
    width: 90%;
    padding: 0;
    position: absolute;
    right: auto;
    text-align: justify;
  } */
  .about {
    height: 220vh;
  }
  .scroll__to__top {
    right: 15px;
  }
}
@media (max-width: 640px) {
  .contact {
    padding: 40px 50px;
  }

  .about {
    height: 220vh;
  }
}
@media (max-width: 600px) {
  section {
    padding: 0 20px;
  }
  .head__text {
    font-size: 20px;
    padding-top: 20px;
  }
}
@media (max-width: 460px) {
  .contact {
    padding: 20px;
  }
  .about {
    max-height: 150vh;
    padding-bottom: 100px;
  }
  .skills {
    height: 120vh;
    padding-bottom: 60px;
  }
  .portfolio__head__text {
    font-size: 25px;
  }
  .column {
    padding: 20px 0px;
  }
  .row a {
    width: 100%;
    padding: 20px 20px 20px 0;
  }
  .skill h1 {
    font-size: 25px;
  }
  .hero .btn,
  .work__btn {
    margin-left: 0;
    width: 100%;
    display: block;
    margin-top: 20px;
  }
  .web__btn {
    display: block;
    margin-top: 10px;
    margin-left: 0;
    width: 100%;
  }

  .scroll__to__top {
    right: 10px;
  }
}
@media (max-width: 420px) {
  .skills {
    height: 100vh;
  }
  .hero {
    height: auto;
    max-height: 90vh;
  }
}
@media (max-width: 400px) {
  .skills {
    height: 120vh;
    padding-bottom: 120px;
  }
  .about {
    padding-bottom: 150px;
  }
}
