.skills{
    display: flex;
    position: relative;
    min-height: 100vh;
}
.skill__head__text{
    width: 40%;
    padding: 100px 0;
}
.skill__head__text h1{
    font-size: 50px;
}
.skill{
    width: 40%;
    position: absolute;
    right: 100px;
}
.skill .skill__header{
    padding: 50px 0 0 0;
    font-size: 40px;
}
.skillset{
    padding: 30px 0 90px 0;
    border-top: 2px solid #ccc;
    font-size: 30px;
    border-width: 3px;
    border-image: linear-gradient(to right, var(--main-color), var(--text-color)) 100% 1;
}
.skill span{
    font-size: 15px;
}