.blog__text{
    text-align: center;
}
.blog{
    padding: 50px 100px;
}
.post img{
    max-width: 350px;
    max-height: 200px;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 1px solid #f1f1f1;
}
.post{
    float: left;
    width: 33%;
    height: 400px;
    padding: 0;
}
article{
    width: 100%;
}
article:after{
    content: "";
    display: table;
    clear: both;
}
.title{
    text-transform: capitalize;
    font-size: 30px;
}
button{
    margin-top: 10px;
}
.text{
    margin: 0;
}


@media screen and (max-width: 800px) {
    .post {
      width: 100%;
      padding: 20px 0;
    }
    .blog{
        padding: 20px 50px;
    }
    .blog__text{
        padding: 0 10px;
    }
  }